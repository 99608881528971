"use client";

import ErrorPage from "components/layout/Error";
import MincaCenteredLogo from "components/layout/MincaCenteredLogo";
export default function Error({
  error
}: {
  error: Error;
}) {
  return <MincaCenteredLogo data-sentry-element="MincaCenteredLogo" data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <ErrorPage error={error} data-sentry-element="ErrorPage" data-sentry-source-file="error.tsx" />
    </MincaCenteredLogo>;
}