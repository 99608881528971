"use client";

import MincaLink from "components/common/MincaLink";
const ErrorPage = ({
  error
}: {
  error: Error;
}) => {
  // TODO: clean this up to not match on a hard-coded string but pull the message from the ServiceError component
  const isUnauth = error.message === "User not authorized to perform request";
  const message1 = isUnauth ? "Unauthorized" : "Oops, something went wrong";
  const message2 = isUnauth ? "You don't appear to have access to this page. If you believe there's been a mistake, please contact your Minca Administrator." : <>
      Don't worry, it's not you, it's us! Please try again later, or contact us
      at <a href="mailto:support@getminca.com">support@getminca.com</a>
    </>;
  return <div className="flex justify-center items-left w-full flex-col max-w-md" data-sentry-component="ErrorPage" data-sentry-source-file="Error.tsx">
      <div className="p-4 mb-8 text-center">
        <h3 className="font-bold mb-2">{message1}</h3>
        <p className="text-base">{message2}</p>
      </div>
      <MincaLink type="button" className="text-center flex mx-auto" href="/" data-sentry-element="MincaLink" data-sentry-source-file="Error.tsx">
        Go Home
      </MincaLink>
    </div>;
};
export default ErrorPage;